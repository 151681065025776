.table-responsive {
    overflow-x: auto;
}

.table thead th {
    white-space: nowrap;
}

@media (max-width: 768px) {
    .card-header .row > div {
        margin-top: 10px; /* Ensure space between stacked elements on mobile */
    }
}

.btn-circle {
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-circle i {
    font-size: 16px;
}
