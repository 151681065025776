.drag-drop-zone {
    border: 2px dashed #6c757d;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    background-color: #f8f9fa;
    position: relative;
    cursor: pointer;
  }
  
  .drag-drop-content {
    pointer-events: none;
  }
  
  .drag-drop-zone:hover {
    background-color: #e9ecef;
  }
  
  .file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  
