/* Modal Styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 80%; /* Default width */
    max-width: 600px;
    position: relative;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

/* For smaller screens, ensure the modal is not too wide */
@media (max-width: 767px) {
    .modal-content {
        width: 50%;
    }
}

/* Table responsiveness */
.table-responsive {
    overflow-x: auto;
}

/* Button and layout adjustments for mobile */
@media (max-width: 767px) {
    .btn-light {
        width: 50%;
        margin-top: 15px;
    }

    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }

    .d-flex {
        flex-direction: column;
        align-items: flex-start;
    }

    h2 {
        font-size: 1.5rem;
        margin-bottom: 10px;
    }
}
