
/* Container for the technical skills section */
.technical-skills {
    max-width: 500px; /* Optional: Restrict container width */
    margin: 0 auto;   /* Center the container */
    padding: 20px;
  }
  
  /* Input field for adding skills */
  .skill-input {
    width: 60%; /* Reduced width */
    padding: 8px;
    font-size: 14px;
    border-radius: 25px;  /* Rounded corners for input */
    border: 1px solid #ccc;
    margin-bottom: 15px;
    transition: width 0.3s ease; /* Smooth transition for width */
  }
  
  .skill-input:focus {
    outline: none;
    border-color: #007bff;  /* Highlight border color on focus */
  }
  
  /* Container for added tags */
  .tags-input {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    border-radius: 25px;
    padding: 10px;
    border: 1px solid #ccc;
    min-height: 50px;  /* Initial height */
    max-height: 300px; /* Maximum height if too many tags */
    /* Allow resizing */
     /* Scroll if tags exceed max height */
    background-color: #f9f9f9;
  }
  
  /* Styling for individual tags */
  .tag {
    display: flex;
    align-items: center;
    background-color: #e0e0e0;
    color: #333;
    padding: 5px 12px;
    border-radius: 15px;
    font-size: 14px;
  }
  
  /* Button for removing tags (cross mark) */
  /* Style for the tag container */
  .tags-input .tag {
    display: inline-flex;
    align-items: center;
    background-color: #e0e0e0;
    padding: 5px 12px;
    border-radius: 15px;
    margin: 5px;
  }
  
  /* Styling for the cross button inside the tag */
  /* Style for the tag container */
  .tags-input .tag {
    display: inline-flex;
    align-items: center;
    background-color: #e0e0e0;
    padding: 5px 12px;
    border-radius: 15px;
    margin: 5px;
    position: relative; /* Make the tag container the reference point for positioning */
  }
  
  /* Styling for the cross button inside the tag */
  .tags-input .tag .btn-close {
    cursor: pointer;
    font-size: 18px; /* Adjust size of the cross */
    color: #007bff !important;  /* Blue color for the cross */
    background: none;
    border: none;
    position: absolute; /* Absolute positioning to place it at the top right */
    top: -5px; /* Adjust positioning from the top */
    right: -5px; /* Adjust positioning from the right */
    font-weight: bold; /* Make the cross bold */
    padding: 0;
  }
  


              