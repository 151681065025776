/* Modal overlay styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark background for the overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: opacity 0.3s ease;
}

/* Modal content box */
.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
}

/* Close button */
.btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
}

.btn-close:hover {
    color: #e74c3c;
}

/* Modal title */
.modal-title {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 20px;
}

/* Modal body */
.modal-body {
    max-height: 400px;
    overflow-y: auto;
    padding-right: 10px; /* Adds space for the scrollbar */
}

/* Modal item (each detail row) */
.modal-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 1rem;
}

/* Modal value styling */
.modal-value {
    font-weight: 500;
    color: #555;
    word-wrap: break-word;
}

/* Optional: Add responsive design */
@media (max-width: 768px) {
    .modal-content {
        width: 90%;
    }

    .modal-title {
        font-size: 1.2rem;
    }

    .modal-item {
        font-size: 0.9rem;
    }
}
