/* Ensure table has consistent borders */
.language-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .language-table th,
  .language-table td {
    border: 1px solid #dee2e6 !important; /* Ensure all cells have borders */
    text-align: center; /* Center align content for better visual consistency */
    vertical-align: middle;
  }
  
  .language-table th {
    background-color: #f8f9fa; /* Optional: Light background for headers */
  }
  
  /* Remove margin on the table if necessary */
  .language-table tbody tr:last-child td {
    border-bottom: 1px solid #dee2e6 !important;
  }

  .form-scroll-container {
    max-height: 500px;  /* Maximum height for the container before scroll appears */
    overflow-y: auto;   /* Enable vertical scrolling when content overflows */
    overflow-x: hidden; /* Optionally, hide horizontal scrolling */
    padding: 10px;      /* Optional: Add some padding to make the content look better */
}
/* Apply styles to the scrollbar */
.form-scroll-container::-webkit-scrollbar {
    width: 12px;  /* Scrollbar width */
}

/* Style the scrollbar track */
.form-scroll-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light gray background */
    border-radius: 10px;
}

/* Style the scrollbar thumb (the ball) */
.form-scroll-container::-webkit-scrollbar-thumb {
    background: #007bff; /* Default blue */
    border-radius: 10px; /* Rounded edges */
    border: 2px solid #f1f1f1; /* Add spacing to make it look like an icon */
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill=\'%23fff\' viewBox=\'0 0 24 24\'><path d=\'M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8zm-1-11h2v6h-2zm1-3a1.5 1.5 0 1 0 1.5 1.5A1.5 1.5 0 0 0 12 6z\'/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%; /* Adjust size */
}


.form-scroll-container::-webkit-scrollbar-thumb:hover {
    background: #0056b3; 
}

/* Ensuring the last row has a bottom border */
.table-bordered tbody tr:last-child td {
  border-bottom: 1px solid #dee2e6;
}

/* You can also modify other table styles */
.table-bordered {
  border-collapse: collapse;
  width: 100%;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee3e6;
  text-align: center;
  vertical-align: middle;
}

.table-bordered th {
  background-color: #f8f9fa;
}

/* Container for the technical skills section */
.technical-skills {
  max-width: 500px; /* Optional: Restrict container width */
  margin: 0 auto;   /* Center the container */
  padding: 20px;
}

/* Input field for adding skills */
.skill-input {
  width: 60%; /* Reduced width */
  padding: 8px;
  font-size: 14px;
  border-radius: 25px;  /* Rounded corners for input */
  border: 1px solid #ccc;
  margin-bottom: 15px;
  transition: width 0.3s ease; /* Smooth transition for width */
}

.skill-input:focus {
  outline: none;
  border-color: #007bff;  /* Highlight border color on focus */
}

/* Container for added tags */
.tags-input {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border-radius: 25px;
  padding: 10px;
  border: 1px solid #ccc;
  min-height: 150px;  /* Initial height */
  max-height: 300px; /* Maximum height if too many tags */
  /* Allow resizing */
   /* Scroll if tags exceed max height */
  background-color: #f9f9f9;
}

/* Styling for individual tags */
.tag {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  color: #333;
  padding: 5px 12px;
  border-radius: 15px;
  font-size: 14px;
}

/* Button for removing tags (cross mark) */
/* Style for the tag container */
.tags-input .tag {
  display: inline-flex;
  align-items: center;
  background-color: #e0e0e0;
  padding: 5px 12px;
  border-radius: 15px;
  margin: 5px;
}

/* Styling for the cross button inside the tag */
/* Style for the tag container */
.tags-input .tag {
  display: inline-flex;
  align-items: center;
  background-color: #e0e0e0;
  padding: 5px 12px;
  border-radius: 15px;
  margin: 5px;
  position: relative; /* Make the tag container the reference point for positioning */
}

/* Styling for the cross button inside the tag */
.tags-input .tag .btn-close {
  cursor: pointer;
  font-size: 18px; /* Adjust size of the cross */
  color: #007bff !important;  /* Blue color for the cross */
  background: none;
  border: none;
  position: absolute; /* Absolute positioning to place it at the top right */
  top: -5px; /* Adjust positioning from the top */
  right: -5px; /* Adjust positioning from the right */
  font-weight: bold; /* Make the cross bold */
  padding: 0;
}
